<template>
  <div class="container">
    <el-empty
      :image-size="500"
      description="找不到该页面，请返回"
    >
      <el-button type="primary" size="mini" @click="back">返回</el-button>
    </el-empty>
  </div>
</template>

<script>
export default {
  data() {
    return {

    }
  },

  components: {},

  computed: {},

  mounted() {},

  methods: {
    back(){
      this.$router.go(-1);
    },
  },
};
</script>
<style scoped lang="scss">
.el-empty{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /deep/.el-empty__description{
    p{
      font-size: 25px;
      margin: 10px 0;
    }
  }
}
</style>
